import Vue from "vue";
import VueResource from "vue-resource";

Vue.use(VueResource);
/*
public methods:
  fetch - request a list of items
  get - request a single item
  save - request to save or update a single item
  remove - request to remove a single item
  duplicate - request an item copy
  */

const baseurl = ""; //v1  set to "" after conversion

export default class PublicService {
  async patch_notes() {
    return new Promise((resolve) => {
      let url = `${baseurl}patch_notes/`;
      let request = Vue.http.get(url);
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          //console.log(error);
          let body = ("body" in error && error.body) || {};
          let msg = [];
          for (var i in body) {
            msg.push(i + ": " + body[i]);
          }
          resolve(msg.join("\n"));
        }
      );
    });
  }
}
